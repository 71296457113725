import React from "react"

import SEO from "../components/SEO"
import { Link } from "gatsby"

// import { ReactTypeformEmbed } from "react-typeform-embed"

import { 
  Container, 
  Row, 
  Col, 
  // Button, 
  Card, 
  CardBody 
} from "reactstrap"

import Faq from "../components/FAQ/Home"
// import FooterEmailSignup from "../components/Footer/FooterEmailSigngup"

// import growIllustration from "../../static/images/2/whatYouGet/grow.svg"
// import launchedIllustration from "../../static/images/2/whatYouGet/launch.svg"
// import onlineIllustration from "../../static/images/2/whatYouGet/online.svg"


import brilleaDashboardReport from "../../static/images/2/image_DashboardResults.svg"

import newCustomers from "../../static/images/2/help/icons/icon_newcustomes.svg"
import budget from "../../static/images/2/help/icons/icon_budget.svg"
import budgetUs from "../../static/images/2/help/icons/icon_budget_en-US.svg"
import campaignSetup from "../../static/images/2/help/icons/icon_campaign.svg"

import adCreationBk from "../../static/images/2/help/adCreation.png"
import cleverStrategiesBk from "../../static/images/2/help/cleverstrategies.svg"

import marketingExpert from "../../static/images/2/help/marketing_expert.png"
import marketingExpertBk from "../../static/images/2/help/marketing_expert_bk_flash.png"

import yellowFlash from "../../static/images/2/help/yellow_flash_bk.png"
import greenFlash from "../../static/images/2/help/green_flash_bk.png"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import useTranslations from "../components/useTranslations"
import localeContext from "../utils/localeContext"


const HowWeCanHelp = () => {
  const { locale } = React.useContext(localeContext);
  const { global, HowWeCanHelp } = useTranslations();

    return (
      <>
        <SEO
          title="brillea - helping startups launch & grow"
          description="Get the sales, signups or app downloads you need to launch or grow your startup or small business"
        />


        <div className={"section section_nav_list section_medium text-center"}>
          <Container>
            <Row>
              <Col>


                <h1 className="color_purple mb-4">
                  Leave us to run your paid social &amp; <br className="hidden-sm"/>search advertising
                  {/* Get everything you need for <br className="hidden-sm"/>effective digital marketing */}

                </h1>

                <h2 className="h3 color_purple_light">
                brillea provide everything needed in a simple one stop shop
                {/* We run your ads, so you can run your business */}
                </h2>
                <ul>
                  <li>
                    <Link className="underline_hover" to="#ad_creation">
                      AD CREATION
                    </Link>
                  </li>
                  <li>
                    <Link className="underline_hover" to="#marketing_expert">
                      Ad management &amp; optimisation
                    </Link>
                  </li>
                  <li>
                    <Link className="underline_hover" to="#platform">
                      PLATFORM
                    </Link>
                  </li>
                  <li>
                    <Link className="underline_hover" to="#strategy">
                      GROWTH FRAMEWORK
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={
            "section section_story section_story__adCreation section_medium pb-0"
          }
          id="ad_creation"
        >
          <Container>
            <Row>
              <Col md={{ size: 6, offset: 0 }}>
                <h5>AD CREATION</h5>
                <h2 className="pt-3 pb-3">
                  Need 'thumb-stopping' ads without the headache of creating
                  them?
                </h2>
                <p>
                  We create your ads from the assets you provide us.
                  If you already have ads you want to use, we can make them work
                  harder in all the right places.
                </p>
                <ul className="brillea_bullets">
                  <li>Captivating ads made from your assets</li>
                  <li>Persuasive copy to engage your customers</li>
                  <li>
                    Specifically designed for Facebook, Instagram &amp; Google Search
                  </li>
                </ul>
              </Col>
              <Col
                md={{ size: 6 }}
                className="section_story__adCreation_image_cont"
              >
                <div className="section_story__adCreation_image_wrapper">
                  <img
                    src={adCreationBk}
                    alt="brillea digital marketing ad creation"
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <div className="section_story__image"></div>
        </div>

        <div
          className={
            "section section_story section_story__marketing section_large"
          }
          id="marketing_expert"
        >
          <div className="section_story__image">
            <img src={marketingExpertBk} alt="brillea marketing experts" />
          </div>
          <Container>
            <Row>
              <Col md={{ size: 6 }} className="meCards__wrapper">
                <div className="marketing_expert_bk">
                  <img src={marketingExpert} alt="digital marketing experts" />
                </div>

                <div className={"meCards"}>
                  <Card className="meCards__item meCards__item_1">
                    <CardBody>
                      <div className="meCards_item__icon">
                        <img
                          src={campaignSetup}
                          alt="Campaign setup Run and optimised by brillea for your success"
                        />
                      </div>
                      <div className="meCards_item__content">
                        <p>
                          <strong>Campaign setup</strong>{" "}
                          <span className="hidden-sm">
                            Run and {global.optimise}d by brillea for your success
                          </span>
                        </p>
                      </div>
                    </CardBody>
                  </Card>

                  <Card className="meCards__item meCards__item_2">
                    <CardBody>
                      <div className="meCards_item__icon">
                        <img
                          src={newCustomers}
                          alt="Get New customers, Find audiences and segments through refined targeting"
                        />
                      </div>
                      <div className="meCards_item__content">
                        <p>
                          <strong>New customers</strong>{" "}
                          <span className="hidden-sm">
                            Find unexplored audiences and new segments through
                            refined targeting
                          </span>
                        </p>
                      </div>
                    </CardBody>
                  </Card>

                  <Card className="meCards__item meCards__item_3">
                    <CardBody>
                      <div className="meCards_item__icon">
                        <img
                          src={(locale === 'en-US' ? budgetUs : budget)}
                          alt="Marketing monthly budget recommendations Make your marketing budgets go further"
                        />
                      </div>
                      <div className="meCards_item__content">
                        <p className="hidden-sm">
                          <strong>Monthly budget recommendations</strong> Make
                          your marketing spend go further
                        </p>
                        <p className="display-sm">
                          <strong>Monthly budget amends</strong>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col md={{ size: 6 }}>
                <h5>Ad management &amp; optimisation</h5>
                <h2 className="pt-3 pb-3">
                  Looking for someone to take the hassle out of your digital
                  marketing
                </h2>
                <p>Your dedicated brillea marketing expert</p>
                <ul className="brillea_bullets">
                  <li>Setup, run and optimise your ads for success</li>
                  <li>Share insights, recommendations and answer any questions</li>
                  <li>Weekly &amp; monthly checkins as needed</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={
            "section section_story section_large section_story__dashboard"
          }
          id="platform"
        >
          <div className="section_story__image">
            <img src={yellowFlash} alt="real time marketing dashboard" />
          </div>
          <Container>
            <Row>
              <Col md={{ size: 7, offset: 0 }}>
                <h5 style={{textTransform:"uppercase"}}>Access to the brillea platform</h5>
                <h2 className="pt-3 pb-3">
                  See how your ads are performing without shuffling
                  through spreadsheets?
                </h2>
                <p>
                  Learn more about your business through actionable audience and
                  data insights in our platform that gets smarter over time.
                </p>
                <h6>Your dashboard lets you see</h6>
                <ul className="brillea_bullets mt-3 col_2">
                  <li>Real time reporting</li>
                  <li>What ads are live or paused</li>
                  <li>Spend summary</li>
                  <li>Chat with your marketing expert</li>
                  <li>Action &amp; status summaries</li>
                </ul>
              </Col>
              <Col
                md={{ size: 4, offset: 1 }}
                className="section_story__dashboard_image_wrapper"
              >
                <img
                  src={brilleaDashboardReport}
                  alt="marekting dashboard reports"
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className={
            "section section_story section_story__strategies section_large"
          }
          id="strategy"
        >
          {" "}
          <div className="section_story__image">
            <img src={greenFlash} alt="brillea marketing strategies" />
          </div>
          <Container>
            <Row>
              <Col md={{ size: 6, offset: 0 }}>
                <div className="section_story__strategies_image_wrapper">
                  <img
                    src={cleverStrategiesBk}
                    alt="clever digital marketing strategies"
                  />
                </div>
              </Col>
              <Col md={{ size: 6 }}>
                <h5>Follow a structured growth framework</h5>
                <h2 className="pt-3 pb-3">
                  brillea takes the guesswork out of digital marketing
                </h2>
                <p>
                  {HowWeCanHelp.cleverStrategies.body}
                </p>
                <ul className="brillea_bullets">
                  <li>
                    Sophisticated data driven targeting methodologies to find new customers and get more sales
                  </li>
                  <li>Regular testing to explore new opportunities</li>
                  <li>{global.Optimisation} to improve your ads performance</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className="section section_header section_help_you bk-purple-dark text-center"
>
          <Container>
            <Row>
              <Col md={{ size: 8, offset: 2 }}>
                <h1 className="h2 color_white">
                brillea can help you get more sales whatever stage you're at
                </h1>
              </Col>
            </Row>
        <Row>
              <Col md={{ size: 4, offset: 0 }}>
                <div className="launch_column">
                  <div className="launch_column__image">
                    <img src={launchedIllustration} alt="Ready to launch?" />
                  </div>
                  <h2 className="h3 color_white mt-2">Looking to launch?</h2>
                  <p className="color_white">
                  Your Shopify website (or similar) is all setup. You want to start getting customers and your brand out there.
                  </p>
                  <Button
                    tag={Link} to={"/contact/"}
                    color="link"
                    size="lg"
                    className="btn-link-yellow"
                  >
                    Help me launch <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Button>
                </div>
              </Col>
              <Col md={{ size: 4, offset: 0 }}>
                <div className="launch_column">
                  <div className="launch_column__image">
                    <img src={growIllustration} alt="Ready to grow?" />
                  </div>
                  <h2 className="h3 color_white mt-2">Ready to grow?</h2>
                  <p className="color_white">
                  You've built your business and got some early customers. Now you want to focus on growth.
                  </p>
                  <Button
                    tag={Link} to={"/contact/"}
                    color="link"
                    size="lg"
                    className="btn-link-yellow"
                  >
                    Help me grow <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Button>
                </div>
              </Col>
              <Col md={{ size: 4, offset: 0 }}>
                <div className="launch_column">
                  <div className="launch_column__image">
                    <img src={onlineIllustration} alt="Moving online?" />
                  </div>
                  <h2 className="h3 color_white mt-2">Moving online?</h2>
                  <p className="color_white">
                  Times have changed and you need to sell direct to your customers through your website.
                  </p>
                  <Button
                    tag={Link} to={"/contact/"}
                    color="link"
                    size="lg"
                    className="btn-link-yellow"
                  >
                    Help me online <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Button>
                </div>
              </Col>
            </Row>
            </Container>
        </div> */}

        <div className="grey_bk">
          <Faq title={"Frequently asked questions"} link={"/faqs/"} />
        </div>
        {/* <FooterEmailSignup /> */}
      </>
    )
}
export default HowWeCanHelp
